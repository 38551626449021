<script>
  /**
   * This component renders a main and nested number progress bar for the subscribe flow
   */
  import screen from '@/helpers/screen'

  export default {
    props: {
      /** An array of objects in the main flow with `position` and `label` properties. Both are strings.
       * Position is what will render inside the circle (i.e.: 1, 2, 3, or A, B, C)
       */
      mainSteps: {
        type: Array,
        required: true,
      },
      /** An array of objects in the expanded/nested flow with `position` and `label` properties.
       */
      nestedSteps: {
        type: Array,
        required: true,
      },
      /** The current step in the main flow (signup view) */
      currentMainStep: {
        type: Number,
        required: true,
      },
      /** The current step in the expanded/nested flow (views after signup) */
      currentNestedStep: {
        type: Number,
        required: true,
      },
      /** Whether the expanded/nested steps should be displayed. */
      showNestedSteps: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      screen,
      lastMainStep() {
        return this.mainSteps?.[this.mainSteps?.length - 1]?.label
      },
      lastMainStepPosition() {
        return this.mainSteps?.length
      },
    },
  }
</script>

<template>
  <div class="bg-black text-dawn w-full h-50px lg:h-85px leading-snug-tight lg:px-9">
    <div
      :class="`h-full max-w-320 mx-auto grid ${
        showNestedSteps ? 'two-grid' : 'grid-cols-3'
      } lg:flex lg:justify-center items-center`"
    >
      <!-- Back arrow for desktop in main steps view -->
      <BaseIcon
        v-if="screen.lg && !showNestedSteps"
        :size="5"
        label="back to membership selection page"
        class="absolute left-0"
        @click="$emit('back')"
      >
        <IconArrowLeft />
      </BaseIcon>
      <!-- Main step in expanded view instead of back arrow -->
      <div
        v-else-if="showNestedSteps"
        class="lg:absolute lg:left-0 flex items-center justify-center"
      >
        <div
          class="bg-dawn text-black rounded-full w-25px h-25px mr-6px flex items-center justify-center font-bold text-3xs"
        >
          {{ lastMainStepPosition }}
        </div>
        <span class="text-xs lg:text-base">
          {{ lastMainStep }}
        </span>
      </div>
      <!-- Main steps -->
      <template v-if="!showNestedSteps">
        <div
          v-for="(step, stepIndex) in mainSteps"
          :key="stepIndex"
          :class="`flex items-center justify-center ${
            stepIndex === 1
              ? 'lg:px-6 border-l-2 border-r-2 border-primary lg:border-none h-full'
              : ''
          }`"
        >
          <div
            :class="`${
              currentMainStep === Number(step.position)
                ? 'bg-dawn text-black'
                : 'border border-dawn'
            } rounded-full w-25px h-25px mr-6px flex items-center justify-center font-bold text-3xs`"
          >
            <BaseIcon v-if="currentMainStep > Number(step.position)" :size="3">
              <IconCheckmarkThick />
            </BaseIcon>
            <span v-else>{{ step.position }}</span>
          </div>
          <span class="text-xs lg:text-base"> {{ step.label }} </span>
        </div>
      </template>
      <!-- Expanded/nested steps -->
      <div v-else class="flex justify-evenly items-center bg-primary lg:bg-black text-3xs h-full">
        <div
          v-for="(step, stepIndex) in nestedSteps"
          :key="stepIndex"
          class="flex lg:flex-col items-center lg:px-10"
        >
          <div
            class="lg:mb-6px border border-dawn rounded-full w-4 h-4 mr-6px lg:mr-0 flex items-center justify-center font-bold text-3xs lg:text-xs"
          >
            <div
              v-if="currentNestedStep === Number(step.position)"
              class="w-8px h-8px rounded-full bg-dawn"
            />
            <div v-else-if="currentNestedStep > Number(step.position)">
              <BaseIcon :size="2">
                <IconCheckmarkThick />
              </BaseIcon>
            </div>
          </div>
          <span>
            {{ step.label }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
  .two-grid {
    grid-template-columns: 1fr 2fr;
  }
</style>
